<!--
File: TreatmentRateEditForm.vue
Description: form for adding/editing a single Treatment Rate.
-->
<template>
  <form>
    <md-card>
      <md-card-content>
        <div class="md-layout-item md-small-size-100 md-size-50">
          <TreatmentsDropdown class='road-class-select-dropdown' :label="$t('treatment.treatment_key')"
            v-model="fk_treatment_type" data-vv-name="fk_treatment_type" :initial_value="fk_treatment_type"
            :is_required="true" v-validate="modelValidations.fk_treatment_type" @input='onTreatmentChange' />

          <!--UnitsDropdown class='road-class-select-dropdown' :label="$t('label.units')" v-model="fk_unit"
            data-vv-name="fk_unit" :initial_value="fk_unit" :is_required="true" /-->

          <md-field :class="[{ 'md-valid': !errors.has('unit_quantity') }, { 'md-error': errors.has('unit_quantity') }]">
            <label>{{ $t('condition.number_units') }}</label>
            <md-input v-model.number="unit_quantity" type="number" data-vv-name="unit_quantity" required
              v-validate="modelValidations.unit_quantity" :min="modelValidations.rate.min_value"></md-input>
          </md-field>

          <FloatInput :class="[{ 'md-valid': !errors.has('rate') }, { 'md-error': errors.has('rate') }]"
            :label="$t('label.rate')" v-model="rate" data-vv-name="rate" v-validate="modelValidations.rate"
            :min="modelValidations.rate.min_value" required />
        </div>

        <div class="md-layout-item md-size-100 text-right">
          <md-button class="md-success" native-type="submit" @click.native.prevent="validate" :disabled="saveBtnDisabled">
            {{ $t('buttons.save') }}
          </md-button>
          <md-button class="md-accent" @click.stop.prevent="onClose">
            {{ $t('buttons.close') }}
          </md-button>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
  import TreatmentsDropdown from '@/pages/Components/TreatmentsDropdown.vue'
  //import UnitsDropdown from '@/pages/Components/UnitsDropdown.vue'
  import FloatInput from '@/pages/Components/FloatInput.vue'
  import messagesMixin from '@/mixins/messagesMixin'
  import { onClose } from '@/mixins/onCloseMixin'

  export default {
    name: 'treatment-rate-edit-form',
    mixins: [messagesMixin],

    data() {
      return {
        fk_treatment_type: 0,
        treatmentDescr: null,
        //fk_unit: 0,
        unit_quantity: 0,
        rate: 0,
        rid: null,

        modelValidations: {
          fk_treatment_type: { required: true, numeric: true, min_value: 1 },
          rate: { required: true, decimal: true, min_value: 1 },
          unit_quantity: { required: true, numeric: true, min_value: 1 }
        }
      }
    },

    props: {
      oper: String
    },

    components: {
      TreatmentsDropdown,
      FloatInput,
      //UnitsDropdown
    },

    mounted() {
      const { rid = null } = this.$route.params
      this.rid = rid
      if (this.oper === 'upd' && rid) {
        this.$store.dispatch('LOAD_TREATMENT_RATE_BY_ID', rid).then((res) => {
          const rate = Array.isArray(res) ? res[0] : res
          this.fk_treatment_type = rate.fk_treatment_type
          this.unit_quantity = rate.unit_quantity
          //this.fk_unit = rate.fk_unit
          this.rate = rate.rate
        })
      }
      this.$nextTick(() => {
        this.$validator.validateAll()
      })
    },

    methods: {
      onClose,

      onTreatmentChange(id, treatmentDescr) {
        this.treatmentDescr = treatmentDescr
      },

      async validate() {
        const isValid = await this.$validator.validateAll()
        if (!isValid) return

        const payload = {
          fk_treatment_type: this.fk_treatment_type,
          unit_quantity: this.unit_quantity,
          rate: this.rate
        }

        let errDesc = ''
        try {
          await this.$store.dispatch('ADD_TREATMENT_RATE', payload)
        } catch (err) {
          errDesc = err.message
        }
        this.savedMessage(errDesc, this.$t('route.treatment_rates'), this.treatmentDescr)

        // To return back to the list
        this.onClose()
      },
    },

    watch: {
    },

    computed: {
      saveBtnDisabled() {
        return this.errors.items.length > 0
      }
    }
  }
</script>
<style>
.md-card {
  margin: 0px 0;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>
